import { jsx as _jsx, jsxs as _jsxs } from "preact/jsx-runtime";
/* eslint-disable @typescript-eslint/naming-convention,react/no-danger */
import DOMPurify from "dompurify";
function TimeToComplete({ innerHtml }) {
    if (!innerHtml) {
        return null;
    }
    return (_jsx("div", { className: "form__group", children: _jsxs("div", { className: "time-to-complete", children: [_jsx("svg", { className: "icon icon--timesave", children: _jsx("use", { "xlink:href": "#timesave" }) }), _jsx("span", { dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(innerHtml) } })] }) }));
}
export default TimeToComplete;
